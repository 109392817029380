// Dependencies
import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

// Components
import { Container } from "./index";
import SEO from "../components/seo";
import TitleImage from "../components/title-image";

interface Props {
  data: {
    allDataJson: {
      edges: {
        node: {};
      };
    };
  };
}

const NotFoundPage: React.FC<Props> = ({ data }) => {
  const content = data.allDataJson.edges[0].node.errorPage;
  return (
    <>
      <SEO />
      <TitleImage title={"Error: 404"} image={content.titleImage} />
      <Container>
        <Header>PRZEPRASZAMY</Header>
        <StyledParagraph>nie mogliśmy znaleźć podanej strony!</StyledParagraph>
        <StyledContent>Wróć na </StyledContent>
        <StyledLink to={"/"}>Naszą Stronę Główną</StyledLink>
        <StyledThumbnail fluid={content.image.childImageSharp.fluid} />
      </Container>
    </>
  );
};

export default NotFoundPage;

const Header = styled.h1`
  font-size: ${props => props.theme.fontSizes[5]}px;
  letter-spacing: 0.1rem;
  margin: ${props => props.theme.space[2]}px 0;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[9]}px;
    margin: ${props => props.theme.space[3]}px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    font-size: ${props => props.theme.fontSizes[11]}px;
    letter-spacing: 0.2rem;
  }
`;

const StyledParagraph = styled.div`
  font-size: ${props => props.theme.fontSizes[3]}px;
  letter-spacing: 0.1rem;
  margin-bottom: ${props => props.theme.space[2]}px;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
    margin-bottom: ${props => props.theme.space[3]}px;
  }

  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    font-size: ${props => props.theme.fontSizes[6]}px;
    margin-bottom: ${props => props.theme.space[4]}px;
  }
`;

const StyledContent = styled.span`
  font-size: ${props => props.theme.fontSizes[2]}px;
  letter-spacing: 0.1rem;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[4]}px;
  }

  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
  }
`;

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes[2]}px;
  letter-spacing: 0.1rem;
  color: ${props => props.theme.colors.primary};

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[4]}px;
  }

  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
  }
`;

const StyledThumbnail = styled(Img)`
  margin: ${props => props.theme.space[5]}px auto;
  object-fit: contain;
  border-radius: 50%;
  overflow: hidden;
  width: 250px;
  height: 250px;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    width: 350px;
    height: 350px;
  }
  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    width: 500px;
    height: 500px;
  }
`;

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          errorPage {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            titleImage {
              childImageSharp {
                fluid(quality: 85, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
